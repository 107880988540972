import React, { useContext, useEffect, useRef, useState } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { GlobalStateContext } from "context/GlobalStateContext";

const Navbar = (props) => {
  const { onOpenSidenav, brandText, sideBarStatus, startGame } = props;
  const searchRef = useRef(null);
  const { profile, setProfile } = useContext(GlobalStateContext); 
  let sessionToken = sessionStorage.getItem('sessionId');
  const [isHovered, setIsHovered] = useState(false);

  // Handle key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isHovered) {
      startGame(true); // Start the game if hovered and Enter is pressed
    }
  };

  // Handle mouse hover
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Add event listener for key press when the component mounts
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isHovered]); // Dependency array includes isHovered

  const fetchTableData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/user-details`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setProfile(response.data);
        return;
      } 
      if (response.response.status === 404) {
        alert('User not found!');
        return;
      }
    } catch (error) {
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!');
      return;
    }
  };

  useEffect(() => {
    if(sessionToken){
      fetchTableData();
    }
  }, [sessionToken]);

  return (
    <>
      <nav className="sticky rounded-2xl z-10 top-4 flex flex-row items-center justify-between bg-white/50 p-2 backdrop-blur-xl dark:bg-secondary-200">
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}/{" "}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="shrink text-xl m-2 text-navy-700 dark:text-white">
            <Link
              to="#"
              className="font-bold hover:text-navy-700 dark:hover:text-white"
            >
              {brandText === 'All Campaigns' && 'Dashboard'}
              {brandText === 'Closed' && (
                <div className="text-sm font-light">
                  <span className="text-red-500">* In questa tabella sono mostrate solo le campagne concluse da meno di 10 giorni</span>
                </div>
              )}
              {brandText === 'On Going' && (
                <div className="text-sm font-light">
                  <span className="text-red-500">* Campagne in partenza tra meno di 14 giorni</span>
                </div>
              )}
            </Link>
          </p>
        </div>
          <div
            className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end gap-2 rounded-full px-2 py-2 dark:!bg-grey-200 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2  xl:hidden">
            {!sideBarStatus ? (
              <span
                className="flex cursor-pointer text-xl text-gray-600 dark:text-white justify-end text-end ps-auto"
                onClick={() => onOpenSidenav(true)}
              >
                <FiAlignJustify className="h-5 w-5" />
              </span>
            ) : (
              <span
                className="flex cursor-pointer text-xl text-gray-600 dark:text-white justify-end text-end ps-auto"
              >
                <IoClose className="h-5 w-5" />
              </span>
            )}
          </div>
          <div className="xl:block hidden">
            <div
              className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end text-end gap-2 rounded-full px-2 py-2 dark:!bg-grey-200 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"
              ref={searchRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave} // Reset hover state
            >
              <img
                className="h-10 w-10 rounded-full"
                src={`https://avatar.iran.liara.run/username?username=${profile?.name || 'Anonymous'}+${profile?.surname || 'Anonymous'}`}
                alt="User Profile"
              />
            </div>
          </div>
      </nav>
    </>
  );
};

export default Navbar;
