import React, { useEffect, useRef, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { ImUserPlus, ImUserMinus  } from "react-icons/im";
import { useNavigate } from 'react-router-dom';

const AssignPopup = ({ show, onClose, rowData }) => {
  const [users, setUsers] = useState([]);
  const [assignUsers, setAssignUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const popupDetailRef = useRef(null);
  let sessionToken = sessionStorage.getItem('sessionId');

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
      
      fetchUsers();
      fetchAssign();
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  const handleClickOutside = (event) => {
    if (popupDetailRef.current && !popupDetailRef.current.contains(event.target)) {
      onClose();
    }
  };

  // Fetch users from API
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setUsers(response.data);
        return;
      } 
      
    } catch (error) {
      
      if (error.response.status === 404) {
        alert('Non ci sono utenti disponibili al momento!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
        console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  const fetchAssign = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/all-assignments`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setAssignUsers(response.data);
        return;
      } 

    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
        console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  // Assign a campaign to the user
  const handleAssign = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks/assign`, {
          "task_id": rowData.task_id,
          "user_id": userId      
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 201) {
        // alert('La campagna è stata assegnata con successo!');
        fetchAssign();  // Refresh the assignation data
        return;
      } 

    } catch (error) {
      if (error.response.status === 400) {
        alert('task già assegnata a questo utente!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
        console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  // Unassign a campaign from the user
  const handleUnassign = async (userId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/delete-assignment/${rowData.task_id}/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        // alert('La campagna è stata rimossa da questo utente!');
        fetchAssign();  // Refresh the assignation data
        return;
      } 

    } catch (error) {
      if (error.response.status === 404) {
        alert('Assegnazione non trovata!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
        console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  const assignedUserCount = assignUsers.filter(assign => assign.task_id === rowData.task_id).length;

  const isAssigned = (userId) => {
    return assignUsers.some(assign => assign.user_id === userId && assign.task_id === rowData.task_id);
  };

    // Step 2: Filter users based on the search term
    const filteredUsers = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.surname.toLowerCase().includes(searchTerm.toLowerCase())
    );
  

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
      <div
        ref={popupDetailRef}
        className="bg-white text-black-500 rounded-lg shadow-lg w-full max-w-md h-auto md:max-w-lg md:h-3/4 relative overflow-hidden"
      >
        <div className="flex justify-between items-center space-x-2 p-5 bg-black-500 z-50">
          <h2 className="text-xl font-semibold text-white ms-6 flex items-center">
            <FaUsers className="text-white me-4" />
            Collaborators {assignedUserCount}
          </h2>
          <button onClick={onClose} className="text-red-500">
            <IoClose className="text-xl" />
          </button>
        </div>
        <div className="absolute inset-0 overflow-auto scrollbar-hidden mt-20 pb-10 px-10 z-10">
        <div className="p-5">
          <input
            type="text"
            placeholder="Cerca per nome o cognome"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
          <table className="w-full text-left border-collapse">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="py-2 px-4">Nome</th>
                <th className="py-2 px-4">Cognome</th>
                <th className="py-2 px-4">Assegnazioni</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr key={user.id} className="border-b border-gray-300">
                    <td className="py-2 px-4">{user.name}</td>
                    <td className="py-2 px-4">{user.surname}</td>
                    <td className="py-2 px-4">
                      {isAssigned(user.id) ? (
                        <button
                          onClick={() => handleUnassign(user.id)}
                          className=" text-red-600 font-normal px-4 py-2 rounded-md flex items-center"
                        >
                          <ImUserMinus className='text-red-600 me-2' />
                          Rimuovi
                        </button>
                      ) : (
                        <button
                          onClick={() => handleAssign(user.id)}
                          className="text-green-600 font-normal px-4 py-2 rounded-md flex items-center"
                        >
                          <ImUserPlus className='text-green-600 me-2' />
                          Aggiungi
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center py-4">
                    Non ci sono utenti da visualizzare!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AssignPopup;
