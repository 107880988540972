import React from 'react';

export default class GameOverScreen extends React.Component {
	constructor(args) {
		super(args);
		this.state = { score: args.score };
	}

	render() {
		return (
			<div className="game-over-screen">
				<span className="vt323-regular text font-semibold">Oh no! Hai lasciato che le big tech conquistassero il mondo!</span>
				<span className="vt323-regular text font-semibold">Punteggio: { this.state.score }</span>
				<span className="vt323-regular text font-semibold">Premi ENTER per riprovare, oppure torna a lavorare: il cliente non aspetta!</span>
			</div>
		);
	}
}
