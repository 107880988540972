import React, { useEffect, useState, useContext } from 'react';
import { FaSyncAlt, FaPen, FaPlus, FaSearch, FaTrash, FaUsers } from 'react-icons/fa'; // Import FaTrash for delete icon
import EditModal from './EditModal';
import DetailModal from './DetailPopup';
import DeleteModal from '../components/modals/DeleteModal'; // Import DeleteModal
import axios from 'axios';
import { GlobalStateContext } from '../context/GlobalStateContext';
import AssignationPopUp from '../components/AssignationPopUp';
import { useNavigate } from 'react-router-dom';


const ActiveDataTable = () => {
  const { tableGlobalData, setTableGlobalData } = useContext(GlobalStateContext); 
  const [tableData, setTableData] = useState(tableGlobalData);
  const [filteredData, setFilteredData] = useState(tableGlobalData);
  const [searchQuery, setSearchQuery] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
  const [currentRowData, setCurrentRowData] = useState([]);
  const [assignationModalOpen, setAssignationModalOpen] = useState(false);
  let sessionToken = sessionStorage.getItem('sessionId');
  const navigate = useNavigate();

  // External function to format date
  const formatDateToItalian = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return new Date(date).toLocaleDateString('it-IT');
  };

  
  const headers = [
    "Mese", "Cliente", "Brand", "Campagna", "Formato", "Live", "End", 
    "Direz. Commerc.", "Buying", "Invio Piano", "Conferma Piano", 
    "Invio Preno", "Scheda Mat.", "Trafficking", "Arrivo Materiali", 
    "FL Inserito", "DEF Barber", "Consuntivato NETBOX", "Data Gathering", 
    "Report", "Accuen", "D2S", "Reservation", "SMS", "DEM", "Social", "Budget"
  ];


  const fetchTableData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/data/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setTableData(response.data);
        setFilteredData(response.data);
        setTableGlobalData(response.data);
        return;
      } 

    } catch (error) {
        if (error.response.status === 404) {
          alert('Non ci sono ancora campagne create o assegnate a questo account!')
          return;
        }
        if (error.response.status === 419) {
          navigate('/auth/sign-in');
          return;
        }
        console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  const handleRefreshClick = () => {
    setIsRefreshing(true);
    fetchTableData().finally(() => {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2000);
    });
  };

  const openEditModal = (rowData, rowIndex) => {
    setCurrentRowData(rowData);
    setIsEditModalOpen(true);
  };

  const openDetailModal = (rowData) => {
    setCurrentRowData(rowData);
    setIsDetailModalOpen(true);
  };
  

  const openDeleteModal = (rowData, rowIndex) => {
    setCurrentRowData(rowData);
    setIsDeleteModalOpen(true);
  };

  const openAssignationModal = (rowData) => {
    setCurrentRowData(rowData);
    setAssignationModalOpen(true);
  };

  
  const filterData = (query) => {
    const today = new Date();
    const filtered = tableData.filter(row => {
      const liveDate = new Date(row.live?.split('/').reverse().join('-'));
      const endDate = new Date(row.end?.split('/').reverse().join('-'));
      
      return (
        (!query || row.cliente?.toLowerCase().includes(query.toLowerCase())) &&
        (!isNaN(liveDate) && !isNaN(endDate) && today >= liveDate && today <= endDate)
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery, tableData]);


  

  return (
    <div className="bg-white text-black-500 lg:px-10 rounded-xl relative">
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-3xl text-center font-semibold text-[#7030a0]">Active Campaigns</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleRefreshClick}
            className="text-[#ff5d5d] font-semibold flex items-center space-x-2"
            title="Refresh Data"
          >
            <FaSyncAlt
              className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`}
            />
            Refresh
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Cliente..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full text-black">
          <thead>
            <tr className="bg-[#9fea47] text-black-500">
              <th className="py-2 px-2 border border-black-500 text-center">Coll.</th>
              <th className="py-2 px-2 border border-black-500 text-center">Edit</th>
              <th className="py-2 px-2 border border-black-500 text-center">View</th>
              {headers.slice(0, 7).map((header, index) => (
                <th key={index} className="py-2 px-2 border border-black-500 text-center">
                  {header}
                </th>
              ))}
              <th className="py-2 px-2 border border-black-500 text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openAssignationModal(row, rowIndex)}>
                    <FaUsers className='text-blue-700' />
                  </button>
                </td>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openEditModal(row, rowIndex)}>
                    <FaPen className='text-blue-700' />
                  </button>
                </td>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openDetailModal(row)}>
                    <FaSearch className='text-green-700' />
                  </button>
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {row.mese_string}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {row.cliente}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {row.brand}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {row.campagna}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {row.formato}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {formatDateToItalian(row.live)}
                </td>
                <td className="py-2 px-2 border border-black-500 text-center whitespace-nowrap">
                  {formatDateToItalian(row.end)}
                </td>
                <td className="py-2 px-4 border border-black-500 text-center text-black-500">
                  <button onClick={() => openDeleteModal(row, rowIndex)}>
                    <FaTrash className='text-red-700' />
                  </button>
                </td>
              </tr>
            ))}
            {filteredData.length === 0 && (
              <tr>
                <td colSpan={headers.length + 3} className="py-2 px-4 border border-black-500 text-center text-black-500">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <EditModal
        show={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        rowData={currentRowData}
        updateTable={fetchTableData}
      />

      <DetailModal
        show={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
        rowData={currentRowData}
      />

    <DeleteModal
      show={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      updateTable={fetchTableData}
      rowData={currentRowData}
    />

      <AssignationPopUp
        show={assignationModalOpen}
        onClose={() => setAssignationModalOpen(false)}
        rowData={currentRowData}
        updateTable={fetchTableData}
      />

    </div>
  );
};

export default ActiveDataTable;
