import axios from 'axios';
import React, { useState } from 'react';

const DeleteModal = ({ show, onClose, rowData, updateTable }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let sessionToken = sessionStorage.getItem('sessionId');

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/tasks/delete/${rowData.task_id}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setShowSuccessModal(true);
        setLoading(false);
        return;
      }else{
        alert('Ops, qualcosa è andato storto!');
        setLoading(false);
      }
    } catch (error) {
      console.log('Error during API request:', error);
      setLoading(false);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  const handleCloseModal = () => {
    onClose();
    setShowSuccessModal(false);
    updateTable()
  }

  // External function to format date
  const formatDateToItalian = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return new Date(date).toLocaleDateString('it-IT');
  };

  return (
    show && (
      <div className="fixed inset-0 glassmorphism bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl mb-4">Sei sicuro di voler cancellare definitivamente <br /> la seguente campagna?</h2>
          <div className="my-4">
            <ul>
              <li><b>Cliente:</b> {rowData.task_title}</li>
              <li className={`${rowData.priority === 1 ? 'text-red-500' : rowData.priority === 0 ? 'text-yellow-500' : 'text-green-500'}`}><b className='text-black-900'>Priorità:</b> {rowData.priority === 1 && 'High'} {rowData.priority === 0 ? 'Medium' : 'Low'}</li>
              <li><b>Scadenza:</b> {formatDateToItalian(rowData.end_date)}</li>
            </ul>
          </div>
          <div className="flex justify-center space-x-5">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={onClose}
            >
              Torna indietro
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleDelete}
            >
            {loading ? 'Caricamento . . .' : 'Cancella'}
            </button>
          </div>
        </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Congratulazioni!</h3>
            <p>Hai eliminato il tuo task con successo!</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-red-500 text-white py-2 px-10 rounded"
            >
              Prosegui
            </button>
          </div>
        </div>
      )}
      </div>
    )
  );
};

export default DeleteModal;
