import React, { useState, useEffect, useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { GlobalStateContext } from 'context/GlobalStateContext';
import { useNavigate } from 'react-router-dom';


// External function to format date
const formatDateToItalian = (date) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return 'Inserisci una data';
  }
  return new Date(date).toLocaleDateString('it-IT');
};

const CreateTaskModal = ({ show, onClose, updateTable }) => {
  // Create a local state to hold the edited data, starting with the rowData values
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  let sessionToken = sessionStorage.getItem('sessionId');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confettiCanvas, setConfettiCanvas] = useState(null);
  const { profile } = useContext(GlobalStateContext); 
  const navigate = useNavigate();

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.className = 'confetti-canvas';
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '9999';
    document.body.appendChild(canvas);
    setConfettiCanvas(canvas);
    return () => {
      document.body.removeChild(canvas);
    };
  }, []);

  const handleCloseModal = () => {
    onClose();
    setShowSuccessModal(false);
    updateTable()
  }


  const launchConfetti = () => {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (confettiCanvas) {
      const confettiInstance = confetti.create(confettiCanvas, {
        resize: true,
        useWorker: true,
      });

      // Launch confetti in three different directions
      confettiInstance({
        angle: randomInRange(55, 70),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(85, 95),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(110, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }
  };


  const cleanAllData = () => {
      setTitle('');
      setDetail('');
      setEndDate('');
      setStatus('');
    }

  const updateTableData = async () => {
    const updatedData = {
      task_title: title,
      task_detail: detail,
      end_date: endDate ? new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1)).toISOString() : '',
      priority: status,
      status: '0'
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks/create`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 201) {
        setShowSuccessModal(true);
        launchConfetti();
        cleanAllData();
        return;
      } 

    } catch (error) {
      if (error.response.status === 400) {
        alert('Controlla di aver compilato tutti i campi!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };


  return (
    show && (
      <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
        <div className="bg-white text-black rounded-lg shadow-lg w-full max-w-md md:max-w-lg h-auto  relative overflow-hidden">
          <div className="flex justify-between items-center p-4 bg-black-500 rounded-t-lg">
            <h2 className="text-xl font-semibold text-white">Create a new Task</h2>
            <button onClick={onClose} className="text-red-500">
              <IoClose className="text-xl" />
            </button>
          </div>
          <div className="overflow-y-auto scrollbar-hidden py-4 px-10 mt-2 h-[calc(100%-80px)]">
            <div className="space-y-4">
              <div className="flex flex-col">

                <label className="font-semibold">Titolo:</label>
                <input
                  value={title} // Make sure the value is the current selected month
                  onChange={(e) => setTitle(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Dettaglio:</label>
                <input
                  value={detail} // Make sure the value is the current selected month
                  onChange={(e) => setDetail(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Scadenza:</label>
                <DatePicker
                  selected={endDate} // Use 'selected' prop for controlled component
                  onChange={(date) => setEndDate(date)} // Ensure you set the Date object
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2 w-full"
                />

                <label className="font-semibold">Priorità:</label>
                <select
                  value={status} // Make sure the value is the current selected month
                  onChange={(e) => setStatus(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      Medium
                    </option>
                    <option value={1}>
                      High
                    </option>
                    <option value={2}>
                      Low
                    </option>
                </select>
              </div>
            </div>
            <div className="mx-auto flex justify-center space-x-2 my-6">
            <button
                onClick={onClose}
                className="w-36 py-2 bg-red-500 text-white rounded-lg"
              >
                Cancella
              </button>
              <button
                onClick={updateTableData}
                className="w-36 py-2 bg-blue-500 text-white rounded-lg"
              >
                Crea
              </button>
            </div>
          </div>
        </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Congratulazioni!</h3>
            <p>Hai Creato la tuo Task con successo!</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-green-500 text-white py-2 px-10 rounded"
            >
              Prosegui
            </button>
          </div>
        </div>
      )}
      </div>
    )
  );
};

export default CreateTaskModal;
