import React, { Component } from 'react';

export default class ControlOverlay extends React.Component {
	constructor(args) {
		super(args);
		this.state = { score: args.score };
	}

	render() {
		return (
			<div>
				<span className="vt323-regular text font-semibold text-green-600 text-center py-4 text-lg">Arrows to move, Space to shoot!</span>
			</div>
			);
	}
}