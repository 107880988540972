import React, { useEffect, useState, useContext } from 'react';
import { FaFlag, FaSyncAlt, FaUserPlus, FaTrash, FaRegCalendarTimes, FaPlus, FaPen } from 'react-icons/fa';
import EditTasksModal from './EditTasksModal';
import DeleteModalTask from '../components/modals/DeleteModalTask';
import axios from 'axios';
import { GlobalStateContext } from '../context/GlobalStateContext';
import AssignationTask from '../components/AssignationTask';
import AddTask from '../components/AddTask';
import { BsChatDots } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';



const Notes = () => {
  const { tableGlobalData, setTableGlobalData } = useContext(GlobalStateContext);
  const [tableData, setTableData] = useState(tableGlobalData);
  const [filteredData, setFilteredData] = useState([]); // Initialize as empty array
  const [searchQuery, setSearchQuery] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [assignationModalOpen, setAssignationModalOpen] = useState(false);
  const [isAddRowModalOpen, setIsAddRowModalOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  let sessionToken = sessionStorage.getItem('sessionId');
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);


  // Function to fetch tasks from the API
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      
      if (response.status === 200) {
        setErrorText('');
        setTasks(response.data); // Assuming your API returns the tasks directly
        setTableData(response.data); // If needed to set global state
        setFilteredData(response.data); // If needed for filtering
      }
    } catch (error) {
      if(error.response.status === 404){
        setErrorText('Non ci sono task da visualizzare al momento.');
        setTasks([]); // Assuming your API returns the tasks directly
        setTableData([]); // If needed to set global state
        setFilteredData([]);
        return
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.error('Error fetching tasks:', error);
      alert('Ops, qualcosa è andato storto!');
    }
  };

  useEffect(() => {
    fetchTasks(); // Fetch tasks on component mount
  }, []);

  const swapStatus = async (idTask, value) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/tasks/update-status/${idTask}`, {status: value}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      
      if (response.status === 200) {
        fetchTasks();
      }

    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.error('Error fetching tasks:', error);
      alert('Ops, qualcosa è andato storto!');
    }
  };

  const handleRefreshClick = () => {
    setIsRefreshing(true);
    fetchTasks().finally(() => {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2000);
    });
  };

  const formatDateToItalian = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return new Date(date).toLocaleDateString('it-IT');
  };

  const openAssignationModal = (rowData) => {
    setCurrentRowData(rowData);
    setAssignationModalOpen(true);
  };

  const openDeleteModal = (rowData) => {
    setCurrentRowData(rowData);
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (rowData) => {
    setCurrentRowData(rowData);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    if (isInitialLoad) {
      // Filter and sort tasks based on search query
      const filtered = tasks
        .filter(task => 
          task.task_title.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          // Sort by status first (0, 1, 2)
          if (a.status !== b.status) {
            return a.status - b.status;
          }
          // Then sort by priority (1, 0, 2)
          const priorityOrder = [1, 0, 2, null]; // Define the custom order
          return priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority);
        });

      setFilteredData(filtered);
      setIsInitialLoad(false); // Set to false after the initial load
    }
  }, [tasks, isInitialLoad]); // Runs only once when `tasks` change or component mounts

  // Effect to update filtered data based on search query changes
  useEffect(() => {
    const filtered = filteredData
      .filter(task => 
        task.task_title.toLowerCase().includes(searchQuery.toLowerCase())
      );

    setFilteredData(filtered);
  }, [searchQuery]);
    
  return (
    <div className="bg-white text-black-500 lg:px-10 rounded-xl relative">
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-3xl text-center font-semibold text-[#7030a0]">To Do List</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsAddRowModalOpen(true)}
            className="text-white bg-blue-500 px-5 py-2 rounded-full font-semibold flex items-center space-x-2 hover:bg-blue-700"
            title="Add New Row"
          >
            <FaPlus className='mr-2' />
            Add Task
          </button>
          <button
            onClick={handleRefreshClick}
            className="text-[#ff5d5d] font-semibold flex items-center space-x-2"
            title="Refresh Data"
          >
            <FaSyncAlt className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`} />
            Refresh
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Notes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="overflow-x-auto">
        <ul className="space-y-4 text-[#000]">
          {errorText}
          {filteredData.map(task => ( // Use filteredData instead of tasks
            <li key={task.id} className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-sm">
              <div>
                <h3 className="text-lg font-medium">{task.task_title}</h3>
                <p className="text-sm text-gray-600">{task.task_detail}</p>
                <div className="flex space-x-4 mt-2 text-sm text-gray-600">
                  <span className={`flex items-center ${task.priority === 1 ? 'text-red-500' : task.priority === 0 ? 'text-yellow-500' : 'text-green-500'}`}>
                    <FaFlag className="mr-2 me-2" /> {task.priority === 1 ? 'High' : task.priority === 0 ? 'Medium' : 'Low'}
                  </span>
                  <button
                    onClick={() => openAssignationModal(task)}
                    className="text-blue-500 rounded-md hover:text-blue-600 flex items-center"
                  >
                    <FaUserPlus className="mr-2" />
                    Aggiungi Utente
                  </button>
                  <span className="flex items-center">
                    <FaRegCalendarTimes className="mr-2" /> {formatDateToItalian(task.end_date)}
                  </span>
                  <button
                    onClick={() => openEditModal(task)}
                    className="text-blue-500 rounded-md hover:text-blue-600 flex items-center"
                  >
                    <FaPen className="mr-2" />
                    Modifica
                  </button>
                  <button
                    onClick={() => openDeleteModal(task)} // Open delete modal
                    className="text-red-500 rounded-md hover:text-red-600 flex items-center"
                  >
                    <FaTrash className="mr-2" />
                    Elimina
                  </button>
                </div>
              </div>
              <div className="flex space-x-2 flex justify-end">
                {task.status === 0 &&
                    <button
                      onClick={() => swapStatus(task.task_id, 1)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md flex items-center w-[7rem] ms-5 justify-center"
                    >
                     To Do
                    </button>
                }
                {task.status === 1 &&
                    <button
                      onClick={() => swapStatus(task.task_id, 2)}
                      className="px-4 py-2 bg-[#743aa0dc] text-white rounded-md flex items-center w-[7rem] ms-5 justify-center"
                    >
                      In Progress
                    </button>
                }
                {task.status === 2 &&
                    <button
                      onClick={() => swapStatus(task.task_id, 0)}
                      className="px-4 py-2 bg-grey-100 text-white rounded-md flex items-center w-[7rem] ms-5 justify-center"
                    >
                      Done
                    </button>
                }                      
              </div>
            </li>
          ))}
        </ul>
      </div>

      <AddTask
        show={isAddRowModalOpen}
        onClose={() => setIsAddRowModalOpen(false)}
        updateTable={fetchTasks}
      />

      <EditTasksModal
        show={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        rowData={currentRowData}
        updateTable={fetchTasks}
      />

      <DeleteModalTask
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        updateTable={fetchTasks}
        rowData={currentRowData}
      />

      <AssignationTask
        show={assignationModalOpen}
        onClose={() => setAssignationModalOpen(false)}
        rowData={currentRowData}
        updateTable={fetchTasks}
      />

    </div>
  );
};

export default Notes;
