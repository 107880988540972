import React, { Component } from 'react';

export default class TitleScreen extends React.Component {
	render() {
		return (
			<div className='text-center flex justify-center'>
				<p className="text-center vt323-regular font-semibold text-green-500">React Invaders</p>
				<p className="text-center vt323-regular text-red-500">Press Enter to start the game!</p>
			</div>
			);
	}
}