import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DeleteUserModal = ({ show, onClose, rowData, updateTable }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  let sessionToken = sessionStorage.getItem('sessionId');
  console.log(rowData)
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/clients/delete-client/${rowData.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setShowSuccessModal(true);
        return;
      } 
    } catch (error) {
      if (error.response.status === 404) {
        alert('Utente non trovato o inattivo!')
        return;
      }
      if (error.response.status === 500) {
        alert('Ops, qualcosa è andato storto!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setShowSuccessModal(false);
    updateTable()
  }

  return (
    show && (
      <div className="fixed inset-0 glassmorphism bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl mb-4">Sei sicuro di voler cancellare definitivamente <br /> Il seguente Cliente?</h2>
          <div className="my-4">
            <ul>
              <li><b>Cliente:</b> {rowData.client_name}</li>
              <li><b>Company:</b> {rowData.company}</li>
            </ul>
          </div>
          <div className="flex justify-center space-x-5">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={onClose}
            >
              Torna indietro
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleDelete}
            >
              Cancella
            </button>
          </div>
        </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Congratulazioni!</h3>
            <p>Hai eliminato l'utente con successo!</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-red-500 text-white py-2 px-10 rounded"
            >
              Prosegui
            </button>
          </div>
        </div>
      )}
      </div>
    )
  );
};

export default DeleteUserModal;
