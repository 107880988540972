import React, { useState, useEffect } from 'react';
import { FaPlus, FaSyncAlt, FaPen, FaTrash, FaUserPlus } from 'react-icons/fa';
import axios from 'axios';
import AddClientModal from './AddNewClient'; // Importa il componente AddUserModal
import DeleteClientModal from './modals/DeleteClientModal';
import { useNavigate } from 'react-router-dom';

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
  const [currentRowData, setCurrentRowData] = useState([]);
  let sessionToken = sessionStorage.getItem('sessionId');
  const navigate = useNavigate();

  // Funzione per recuperare gli utenti dal backend
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients/all-clients`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      setUsers(response.data);
    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      if (error.response.status === 404) {
        setUsers([])
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!')
    }
  };

  // Funzione per aggiornare i dati
  const handleRefreshClick = async () => {
    setIsRefreshing(true);
    await fetchUsers();
    setIsRefreshing(false);
  };

// Filtra gli utenti in base alla ricerca su nome o username
const filteredData = users.filter((user) => 
    (user.client_name && user.client_name.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.company && user.company.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  

  useEffect(() => {
    fetchUsers();
  }, []);

  const openDeleteModal = (rowData) => {
    setCurrentRowData(rowData);
    setIsDeleteModalOpen(true);
  };


  return (
    <div className="bg-white text-black-500 lg:px-10 rounded-xl relative">
      <div className="flex justify-between items-center mb-10">
        <h2 className="text-3xl text-center font-semibold text-[#7030a0]">Clients List</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsAddUserModalOpen(true)}
            className="text-white bg-blue-500 px-5 py-2 rounded-full font-semibold flex items-center space-x-2 hover:bg-blue-700"
            title="Add New User"
          >
            <FaPlus className='mr-2' />
            Add Client
          </button>
          <button
            onClick={handleRefreshClick}
            className="text-[#ff5d5d] font-semibold flex items-center space-x-2"
            title="Refresh Data"
          >
            <FaSyncAlt className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`} />
            Refresh
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Name or Surname..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="overflow-x-auto">
        <ul className="space-y-4 text-[#000]">
          {users.length > 0 ? (
              <>
                {filteredData.map(user => (
                  <li key={user.id} className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-sm">
                    <div>
                      <h3 className="text-lg font-medium">{user.client_name}</h3>
                      <p className="text-sm text-gray-600">{user.company}</p>
                    </div>
                    <div className="flex space-x-4 mt-2 text-sm text-gray-600">
                      <button
                        onClick={() => openDeleteModal(user)}
                        className="text-red-500 rounded-md hover:text-red-600 flex items-center"
                      >
                        <FaTrash className="mr-2" />
                        Rimuovi
                      </button>
                    </div>
                  </li>
                ))}
              </>
            )
            :
            <li className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-sm">
              <p>Non ci sono clienti al momento!</p>
            </li>
          }
        </ul>
      </div>
      <AddClientModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        updateTable={fetchUsers}
      />

      <DeleteClientModal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        updateTable={fetchUsers}
        rowData={currentRowData}
      />
    </div>
  );
};

export default UsersTable;
