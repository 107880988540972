import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';


// External function to format date
const formatDateToItalian = (date) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return 'Invalid Date';
  }
  return new Date(date).toLocaleDateString('it-IT');
};

const EditModal = ({ show, onClose, rowData, updateTable }) => {
  // Create a local state to hold the edited data, starting with the rowData values
  const [mese, setMese] = useState(rowData.mese_string);
  const [cliente, setCliente] = useState(rowData.cliente);
  const [brand, setBrand] = useState(rowData.brand);
  const [campagna, setCampagna] = useState(rowData.campagna);
  const [formato, setFormato] = useState(rowData.formato);
  const [live, setLive] = useState(rowData.live); // live date
  const [end, setEnd] = useState(rowData.end); // end date
  const [dirzCommerc, setDirzCommerc] = useState(rowData.drz_commerc);
  const [buying, setBuying] = useState(rowData.buying);
  const [inPiano, setInPiano] = useState(rowData.in_piano);
  const [confPiano, setConfPiano] = useState(rowData.conf_piano);
  const [invioPreno, setInvioPreno] = useState(rowData.in_preno);
  const [schedaMat, setSchedaMat] = useState(rowData.scheda_mat);
  const [trafficking, setTrafficking] = useState(rowData.trafficking);
  const [arrivoMateriali, setArrivoMateriali] = useState(rowData.materiali);
  const [FlInserito, setFlInserito] = useState(rowData.fl_inserito);
  const [defBarber, setDefBarber] = useState(rowData.def_barber);
  const [netbox, setNetbox] = useState(rowData.netbox);
  const [gathering, setGathering] = useState(rowData.gathering);
  const [report, setReport] = useState(rowData.report);
  const [accuen, setAccuen] = useState(rowData.accuen);
  const [d2s, setD2s] = useState(rowData.d2s);
  const [reservation, setReservation] = useState(rowData.reservation);
  const [sms, setSms] = useState(rowData.sms);
  const [dem, setDem] = useState(rowData.dem);
  const [social, setSocial] = useState(rowData.social);
  let sessionToken = sessionStorage.getItem('sessionId');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confettiCanvas, setConfettiCanvas] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.className = 'confetti-canvas';
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '9999';
    document.body.appendChild(canvas);
    setConfettiCanvas(canvas);
    return () => {
      document.body.removeChild(canvas);
    };
  }, []);

  const handleCloseModal = () => {
    onClose();
    setShowSuccessModal(false);
    updateTable()
  }


  const launchConfetti = () => {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (confettiCanvas) {
      const confettiInstance = confetti.create(confettiCanvas, {
        resize: true,
        useWorker: true,
      });

      // Launch confetti in three different directions
      confettiInstance({
        angle: randomInRange(55, 70),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(85, 95),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(110, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }
  };



  // List of months for the dropdown
  const months = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];

  useEffect(() => {
    if (rowData) {
      setMese(rowData.mese_string);
      setCliente(rowData.cliente);
      setBrand(rowData.brand);
      setCampagna(rowData.campagna);
      setFormato(rowData.formato);
      setLive(rowData.live);
      setEnd(rowData.end);
      setDirzCommerc(rowData.drz_commerc);
      setBuying(rowData.buying);
      setInPiano(rowData.in_piano);
      setConfPiano(rowData.conf_piano);
      setInvioPreno(rowData.in_preno);
      setSchedaMat(rowData.scheda_mat);
      setTrafficking(rowData.trafficking);
      setArrivoMateriali(rowData.materiali);
      setFlInserito(rowData.fl_inserito);
      setDefBarber(rowData.def_barber);
      setNetbox(rowData.netbox);
      setGathering(rowData.gathering);
      setReport(rowData.report);
      setAccuen(rowData.accuen);
      setD2s(rowData.d2s);
      setReservation(rowData.reservation);
      setSms(rowData.sms);
      setDem(rowData.dem);
      setSocial(rowData.social);
    }
  }, [rowData]);

  const updateTableData = async () => {
    const updatedData = {
      mese_string: mese,
      cliente: cliente,
      brand: brand,
      campagna: campagna,
      formato: formato,
      live: live,
      end: end,
      drz_commerc: dirzCommerc,
      buying: buying,
      in_piano: inPiano,
      conf_piano: confPiano,
      in_preno: invioPreno,
      scheda_mat: schedaMat,
      trafficking: trafficking,
      materiali: arrivoMateriali,
      fl_inserito: FlInserito,
      def_barber: defBarber,
      netbox: netbox,
      gathering: gathering,
      report: report,
      accuen: accuen,
      d2s: d2s,
      reservation: reservation,
      sms: sms,
      dem: dem,
      social: social,
    };
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/data/update/${rowData.id}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 200) {
        setShowSuccessModal(true);
        launchConfetti();
        return;
      } 

    } catch (error) {
      if (error.response.status === 400) {
        alert('I campi mese, cliente e campagna sono obbligatori!')
        return;
      }
      if (error.response.status === 404) {
        alert('Campagna non trovato o inattiva!')
        return;
      }
      if (error.response.status === 500) {
        alert('Ops, qualcosa è andato storto!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!');
    }
  };


  return (
    show && (
      <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
        <div className="bg-white text-black rounded-lg shadow-lg w-full max-w-md md:max-w-lg h-auto md:h-3/4 relative overflow-hidden">
          <div className="flex justify-between items-center p-4 bg-black-500 rounded-t-lg">
            <h2 className="text-xl font-semibold text-white">Edit Campaign</h2>
            <button onClick={onClose} className="text-red-500">
              <IoClose className="text-xl" />
            </button>
          </div>
          <div className="overflow-y-auto scrollbar-hidden py-4 px-10 mt-2 h-[calc(100%-80px)]">
            <div className="space-y-4">
              <div className="flex flex-col">


                <label className="font-semibold">Mese:</label>
                <select
                  value={mese} // Make sure the value is the current selected month
                  onChange={(e) => setMese(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Select Month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>

                <label className="font-semibold">Cliente:</label>
                <input
                  value={cliente} // Make sure the value is the current selected month
                  onChange={(e) => setCliente(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Brand:</label>
                <input
                  value={brand} // Make sure the value is the current selected month
                  onChange={(e) => setBrand(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Campagna:</label>
                <input
                  value={campagna} // Make sure the value is the current selected month
                  onChange={(e) => setCampagna(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Formato:</label>
                <input
                  value={formato} // Make sure the value is the current selected month
                  onChange={(e) => setFormato(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                </input>

                <label className="font-semibold">Live Date:</label>
                <DatePicker
                  value={formatDateToItalian(live)}
                  onChange={(date) => setLive(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2 w-full"
                />

                <label className="font-semibold">End Date:</label>
                <DatePicker
                  value={formatDateToItalian(end)}
                  onChange={(date) => setEnd(date)}
                  dateFormat="dd/MM/yyyy"
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2 w-full"
                />

                <label className="font-semibold">Direz. Commerc. :</label>
                <select
                  value={dirzCommerc} // Make sure the value is the current selected month
                  onChange={(e) => setDirzCommerc(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Buying:</label>
                <select
                  value={buying} // Make sure the value is the current selected month
                  onChange={(e) => setBuying(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Invio Piano:</label>
                <select
                  value={inPiano} // Make sure the value is the current selected month
                  onChange={(e) => setInPiano(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Conferma Piano:</label>
                <select
                  value={confPiano} // Make sure the value is the current selected month
                  onChange={(e) => setConfPiano(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Invio Preno:</label>
                <select
                  value={invioPreno} // Make sure the value is the current selected month
                  onChange={(e) => setInvioPreno(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Scheda Mat. :</label>
                <select
                  value={schedaMat} // Make sure the value is the current selected month
                  onChange={(e) => setSchedaMat(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Trafficking:</label>
                <select
                  value={trafficking} // Make sure the value is the current selected month
                  onChange={(e) => setTrafficking(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Arrivo Materiali:</label>
                <select
                  value={arrivoMateriali} // Make sure the value is the current selected month
                  onChange={(e) => setArrivoMateriali(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">FL Inserito:</label>
                <select
                  value={FlInserito} // Make sure the value is the current selected month
                  onChange={(e) => setFlInserito(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">DEF Barber:</label>
                <select
                  value={defBarber} // Make sure the value is the current selected month
                  onChange={(e) => setDefBarber(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Netbox:</label>
                <select
                  value={netbox} // Make sure the value is the current selected month
                  onChange={(e) => setNetbox(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Gathering:</label>
                <select
                  value={gathering} // Make sure the value is the current selected month
                  onChange={(e) => setGathering(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Report:</label>
                <select
                  value={report} // Make sure the value is the current selected month
                  onChange={(e) => setReport(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 mt-1 mb-2"
                >
                  <option value="" disabled>Seleziona un'opzione:</option>
                    <option value={0}>
                      NO
                    </option>
                    <option value={1}>
                      SI
                    </option>
                    <option value={2}>
                      FORSE
                    </option>
                </select>

                <label className="font-semibold">Accuen:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md mb-2">
                  <button
                    onClick={() => setAccuen(accuen - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={accuen + ' €'}
                      onChange={(e) => setAccuen(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setAccuen(accuen + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <label className="font-semibold">D2S:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md">
                  <button
                    onClick={() => setD2s(d2s - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={d2s + ' €'}
                      onChange={(e) => setD2s(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setD2s(d2s + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <label className="font-semibold">Reservation:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md">
                  <button
                    onClick={() => setReservation(reservation - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={reservation + ' €'}
                      onChange={(e) => setReservation(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setReservation(reservation + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <label className="font-semibold">SMS:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md">
                  <button
                    onClick={() => setSms(sms - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={sms + ' €'}
                      onChange={(e) => setSms(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setSms(sms + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <label className="font-semibold">DEM:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md">
                  <button
                    onClick={() => setDem(dem - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={dem + ' €'}
                      onChange={(e) => setDem(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setDem(dem + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <label className="font-semibold">Social:</label>
                <div className="flex items-center justify-between mt-1 mb-2 w-full border rounded-md">
                  <button
                    onClick={() => setSocial(social - 1)}
                    className="px-2 py-1 text-red-500 text-2xl font-bold"
                  >
                    -
                  </button>
                  <div>
                    <input
                      type=""
                      value={social + ' €'}
                      onChange={(e) => setSocial(parseFloat(e.target.value) || 0)}
                      className="text-center pe-1 focus:outline-none w-auto font-semibold"
                      step="1"
                      min="0"
                    ></input>
                    
                  </div>
                  <button
                    onClick={() => setSocial(social + 1)}
                    className="px-2 py-1 text-blue-500 font-bold text-2xl rounded"
                  >
                    +
                  </button>
                </div>

                <p className="font-semibold text-center mt-4 text-xl">Tot. Budget: <span className='text-green-700'>{accuen + d2s + reservation + sms + dem + social  + ' €'}</span></p>

              </div>
            </div>
            <div className="flex justify-center space-x-2 mt-6">
              <button
                onClick={onClose}
                className="w-36 py-2 bg-red-500 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={updateTableData}
                className="w-36 py-2 bg-blue-500 text-white rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Congratulazioni!</h3>
            <p>Hai aggiornato la tua campagna con successo!</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-green-500 text-white py-2 px-10 rounded"
            >
              Prosegui
            </button>
          </div>
        </div>
      )}
      </div>
    )
  );
};

export default EditModal;
