import GameObject from './GameObject';
import Bullet from './Bullet';
import imgLogo1 from './assets/facebook.png'; 
import imgLogo2 from './assets/google.png';
import imgLogo3 from './assets/insta.png';
import imgLogo4 from './assets/linkedin.png';

const logos = [imgLogo1, imgLogo2, imgLogo3, imgLogo4]; // Array di loghi

export const Direction = {
  Left: 0,
  Right: 1,
};

export default class Invader extends GameObject {
  constructor(args) {
    super({ position: args.position, onDie: args.onDie, speed: 1, radius: 50 });
    this.direction = Direction.Right;
    this.bullets = [];
    this.lastShot = 0;
    this.shootDelay = Date.now();
    
    // Seleziona un'immagine casualmente dall'array di loghi
    this.image = new Image();
    this.imageLoaded = false; // Indica se l'immagine è stata caricata
    const randomLogoIndex = Math.floor(Math.random() * logos.length); // Indice casuale
    this.image.src = logos[randomLogoIndex]; // Imposta l'immagine scelta casualmente

    // Assicurati che l'immagine sia caricata prima di usarla
    this.image.onload = () => {
      this.imageLoaded = true;
    };
  }

  reverse() {
    if (this.direction === Direction.Right) {
      this.position.x -= 10;
      this.direction = Direction.Left;
    } else {
      this.direction = Direction.Right;
      this.position.x += 10;
    }
  }

  update() {
    if (this.direction === Direction.Right) {
      this.position.x += this.speed;
    } else {
      this.position.x -= this.speed;
    }

    let nextShoot = Math.random() * 6 * 1000000;

    let now = Date.now();
    if (now - this.shootDelay > nextShoot && this.bullets.length <= 2 && now - this.lastShot > nextShoot) {
      const bullet = new Bullet({
        position: { x: this.position.x, y: this.position.y - 5 },
        direction: 'down',
      });

      this.bullets.push(bullet);
      this.lastShot = Date.now();
    }
  }

  renderBullets(state) {
    let index = 0;
    for (let bullet of this.bullets) {
      if (bullet.delete) {
        this.bullets.splice(index, 1);
      } else {
        this.bullets[index].update();
        this.bullets[index].render(state);
      }
      index++;
    }
  }

  render(state) {
    if (this.position.y > state.screen.height || this.position.y < 0) {
      this.die();
    }

    this.renderBullets(state);

    const context = state.context;
    context.save();
    context.translate(this.position.x, this.position.y);

    // Verifica se l'immagine è stata caricata prima di disegnarla
    if (this.imageLoaded) {
      context.drawImage(
        this.image,
        -this.radius, // Posiziona l'immagine centrata sull'oggetto
        -this.radius, 
        this.radius * 1.2, // Larghezza dell'immagine
        this.radius * 1.2  // Altezza dell'immagine
      );
    } 
    // Non disegnare nulla se l'immagine non è ancora pronta

    context.restore();
  }
}
