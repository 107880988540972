import Bullet from './Bullet';
import GameObject from './GameObject';
import shipImage from './assets/phdship.png'; // Importa l'immagine della nave

export default class Ship extends GameObject {
  constructor(args) {
    super({ position: args.position, onDie: args.onDie, speed: 2.5, radius: 15 });
    this.bullets = [];
    this.lastShot = 0;

    // Carica l'immagine della nave
    this.image = new Image();
    this.imageLoaded = false; // Indica se l'immagine è stata caricata
    this.image.src = shipImage; // Imposta il percorso dell'immagine

    // Assicurati che l'immagine sia caricata prima di usarla
    this.image.onload = () => {
      this.imageLoaded = true;
    };
  }

  update(keys) {
    if (keys.right) {
      this.position.x += this.speed;
    } else if (keys.left) {
      this.position.x -= this.speed;
    }

    if (keys.space && Date.now() - this.lastShot > 250) {
      const bullet = new Bullet({
        position: { x: this.position.x, y: this.position.y - 5 },
        direction: 'up',
      });

      this.bullets.push(bullet);
      this.lastShot = Date.now();
    }
  }

  renderBullets(state) {
    let index = 0;
    for (let bullet of this.bullets) {
      if (bullet.delete) {
        this.bullets.splice(index, 1);
      } else {
        this.bullets[index].update();
        this.bullets[index].render(state);
      }
      index++;
    }
  }

  render(state) {
    if (this.position.x > state.screen.width) {
      this.position.x = 0;
    } else if (this.position.x < 0) {
      this.position.x = state.screen.width;
    }
    if (this.position.y > state.screen.height) {
      this.position.y = 0;
    } else if (this.position.y < 0) {
      this.position.y = state.screen.height;
    }

    this.renderBullets(state);

    const context = state.context;
    context.save();
    context.translate(this.position.x, this.position.y);

    // Verifica se l'immagine è stata caricata prima di disegnarla
    if (this.imageLoaded) {
      const shipWidth = 30; // Imposta la larghezza desiderata per l'immagine della nave
      const shipHeight = 30; // Imposta l'altezza desiderata per l'immagine della nave

      context.drawImage(
        this.image,
        -shipWidth / 2, // Posiziona l'immagine centrata sull'oggetto
        -shipHeight / 0.5,
        shipWidth * 3, // Larghezza dell'immagine
        shipHeight * 3  // Altezza dell'immagine
      );
    } else {
      // Disegna un semplice triangolo se l'immagine non è ancora caricata
      context.strokeStyle = '#ffffff';
      context.fillStyle = '#ffffff';
      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(0, -25);
      context.lineTo(15, 15);
      context.lineTo(5, 7);
      context.lineTo(-5, 7);
      context.lineTo(-15, 15);
      context.closePath();
      context.fill();
      context.stroke();
    }

    context.restore();
  }
}
