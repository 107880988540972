// src/context/GlobalStateContext.js
import React, { createContext, useState } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [notification, setNotification] = useState([]);
  const [tableGlobalData, setTableGlobalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);


  return (
    <GlobalStateContext.Provider
      value={{
        profile,
        setProfile,
        filteredData,
        setFilteredData,
        tableGlobalData,
        setTableGlobalData,
        notification, 
        setNotification,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
