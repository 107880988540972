import axios from 'axios';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const RecoveryModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSended, setEmailSended] = useState(false);

  const handleSendEmail = async () => {
    if (!email) {
      setErrorMessage('Per favore, inserisci il tuo indirizzo email.');
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/pwd/recovery-password`, { email: email });
      if (response.status === 200) {
        setEmailSended(true); 
        return;
      } 
    } catch (error) {
      console.log('Error during API request:', error);
      if (error.response.status === 404) {
        setErrorMessage("Non ci sono utenti con questo indirizzo email.");
        return;
      }
      if (error.response.status === 500) {
        setErrorMessage("Ops! c'è stato un errore, riprova più tardi.");
        return;
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-gray-700 z-50 glassmorphism">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 mx-10 relative">
        <h3 className="text-xl font-semibold mb-4 text-center text-[#7030a0]">Recupera Password</h3>

        {/* If email has been sent, show the success message */}
        {emailSended ? (
          <div className="text-center">
            <p className="text-green-600 font-semibold">Abbiamo inviato un'email con il link per reimpostare la password!</p>
          </div>
        ) : (
          <div>
            <label className="block mb-2">Indirizzo Email:</label>
            <input
              type="email"
              className="w-full p-2 border rounded mb-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Inserisci qui il tuo indirizzo email"
            />
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-center gap-5 mt-4">
          {!emailSended && (
            <button onClick={handleSendEmail} className="px-10 py-2 text-sm bg-[#8cd13d] text-white rounded">
              Invia
            </button>
          )}
        </div>

        <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default RecoveryModal;
