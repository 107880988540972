import React, { useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';

  // External function to format date
  const formatDateToItalian = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return new Date(date).toLocaleDateString('it-IT');
  };


const OrderDetailsPopup = ({ show, onClose, rowData }) => {
  const popupDetailRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupDetailRef.current && !popupDetailRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full glassmorphism z-50">
      <div
        ref={popupDetailRef}
        className="bg-white text-black-500 rounded-lg shadow-lg w-full max-w-md h-auto md:max-w-lg md:h-3/4 relative overflow-hidden"
      >
        <div className="flex justify-between items-center space-x-2 p-5 bg-black-500 z-50">
          <h2 className="text-xl font-semibold text-white ms-6">Campaign Detail</h2>
          <button
            onClick={onClose}
            className="text-red-500"
          >
            <IoClose className='text-xl' />
          </button>
        </div>
        <div className="absolute inset-0 overflow-auto scrollbar-hidden mt-20 pb-10 px-10 z-10">
          <table className="w-full text-left border-collapse">
            <tbody>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Mese:</td>
                  <td className="py-2 px-4">
                    {rowData.mese_string === '' ? '-' : rowData.mese_string }
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Cliente:</td>
                  <td className="py-2 px-4">
                    {rowData.cliente === '' ? '-' : rowData.cliente }
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Brand:</td>
                  <td className="py-2 px-4">
                    {rowData.brand === '' ? '-' : rowData.brand }
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Campagna:</td>
                  <td className="py-2 px-4">
                    {rowData.campagna === '' ? '-' : rowData.campagna }
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Formato:</td>
                  <td className="py-2 px-4">
                    {rowData.formato === '' ? '-' : rowData.formato }
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Live:</td>
                  <td className="py-2 px-4">
                    {rowData.live === '' ? '-' : formatDateToItalian(rowData.live)}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">End:</td>
                  <td className="py-2 px-4">
                    {rowData.end === '' ? '-' : formatDateToItalian(rowData.end)}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Direz. Commerc. :</td>
                  <td className="py-2 px-4">
                    {rowData.drz_commerc === 0 && 'NO'}
                    {rowData.drz_commerc === 1 && 'SI'}
                    {rowData.drz_commerc === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Buying:</td>
                  <td className="py-2 px-4">
                    {rowData.buying === 0 && 'NO'}
                    {rowData.buying === 1 && 'SI'}
                    {rowData.buying === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Invio Piano:</td>
                  <td className="py-2 px-4">
                    {rowData.in_piano === 0 && 'NO'}
                    {rowData.in_piano === 1 && 'SI'}
                    {rowData.in_piano === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Conferma Piano:</td>
                  <td className="py-2 px-4">
                    {rowData.conf_piano === 0 && 'NO'}
                    {rowData.conf_piano === 1 && 'SI'}
                    {rowData.conf_piano === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Invio Preno:</td>
                  <td className="py-2 px-4">
                    {rowData.in_preno === 0 && 'NO'}
                    {rowData.in_preno === 1 && 'SI'}
                    {rowData.in_preno === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Scheda Mat. :</td>
                  <td className="py-2 px-4">
                    {rowData.scheda_mat === 0 && 'NO'}
                    {rowData.scheda_mat === 1 && 'SI'}
                    {rowData.scheda_mat === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Trafficking:</td>
                  <td className="py-2 px-4">
                    {rowData.trafficking === 0 && 'NO'}
                    {rowData.trafficking === 1 && 'SI'}
                    {rowData.trafficking === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Arrivo Materiali:</td>
                  <td className="py-2 px-4">
                    {rowData.materiali === 0 && 'NO'}
                    {rowData.materiali === 1 && 'SI'}
                    {rowData.materiali === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">FL Inserito:</td>
                  <td className="py-2 px-4">
                    {rowData.fl_inserito === 0 && 'NO'}
                    {rowData.fl_inserito === 1 && 'SI'}
                    {rowData.fl_inserito === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">DEF Barber:</td>
                  <td className="py-2 px-4">
                    {rowData.def_barber === 0 && 'NO'}
                    {rowData.def_barber === 1 && 'SI'}
                    {rowData.def_barber === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Consuntivato NETBOX:</td>
                  <td className="py-2 px-4">
                    {rowData.netbox === 0 && 'NO'}
                    {rowData.netbox === 1 && 'SI'}
                    {rowData.netbox === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Data Gathering:</td>
                  <td className="py-2 px-4">
                    {rowData.gathering === 0 && 'NO'}
                    {rowData.gathering === 1 && 'SI'}
                    {rowData.gathering === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Report:</td>
                  <td className="py-2 px-4">
                    {rowData.report === 0 && 'NO'}
                    {rowData.report === 1 && 'SI'}
                    {rowData.report === 2 && 'FORSE'}
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Accuen:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.accuen).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">D2S:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.d2s).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Reservation:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.reservation).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">SMS:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.sms).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">DEM:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.dem).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-medium py-2 px-4">Social:</td>
                  <td className="py-2 px-4">
                    {parseFloat(rowData.social).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="font-semibold py-2 px-4">Budget:</td>
                  <td className="py-2 px-4 font-semibold">
                    {parseFloat(rowData.budget).toLocaleString('it-IT',{ minimumFractionDigits: 2, maximumFractionDigits: 2})} €
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPopup;
