import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { FaPlus, FaSyncAlt } from 'react-icons/fa';
import { GlobalStateContext } from '../context/GlobalStateContext';
import { FaCheckDouble } from "react-icons/fa6";
import TaskResponsePopup from './TakeResponsePopup';
import { useNavigate } from 'react-router-dom';

const ChatComponent = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const sessionToken = sessionStorage.getItem('sessionId');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { profile, setNotification } = useContext(GlobalStateContext);
  const [users, setUsers] = useState([]);
  const [newChat, setNewChat] = useState(false);
  const navigate = useNavigate();
  const lastMessageRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeChat && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory, activeChat]);

  const fetchChatHistory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/all-history/all`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      setChatHistory(response.data);
    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!');
    }
  };

  const handleUserSelect = async (id) => {
    const bodyNewChat = {
        message: '', 
        to_user_id: id, 
        referrer_task: null, 
        referrer_campaign: null, 
        creator_name: profile.name + ' ' + profile.surname
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/create`, bodyNewChat, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      fetchChatHistory();
    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!');    
    }
  };

  const fetchSeenChat = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/unread/count`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      if (response.data && response.data.length > 0) {
        setNotification(response.data[0].unread_count);
        return;
      }
      if (response.data.length === 0) {
        setNotification(0);
        return;
      }
      setNotification(response.data[0].unread_count);
    } catch (error) {
      // console.log('Error during API request:', error);
      // alert('Ops, qualcosa è andato storto!');     
    }
  };

  const setSeenChat = async (id) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/chat/mark-seen/${id}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      fetchChatHistory();
      fetchSeenChat();

    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!'); 
    }
  };



  const openChat = (userId) => {
    setActiveChat(userId);
    setSeenChat(userId);
  };

  const sendMessage = async (chatId) => {
    if (newMessage.trim() === ''){
       alert('Devi compilare il campo messaggio!')
        return; 
    } 
    const bodyMessage = {
      message: newMessage,
      to_user_id: chatId,
      creator_name: profile.name + ' ' + profile.surname,
    };

    try {
      const toUserId = activeChat;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/create`, bodyMessage, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });

      fetchChatHistory();
      setNewMessage('');
    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!'); 
    }
  };


  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      setUsers(response.data);
    } catch (error) {
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }
      console.log('Error during API request:', error);
      alert('Ops, qualcosa è andato storto!'); 
    }
  };


  // Function to filter users based on the search query
  const filteredUsers = users.filter(user => 
    `${user.name} ${user.surname}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  
  const handleRefreshClick = () => {
    setIsRefreshing(true);
    fetchChatHistory().finally(() => {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 2000);
    });
  };
  
  useEffect(() => {
    fetchChatHistory();
    getAllUsers();
  }, []);

  return (
    <div className="bg-white text-black-500 lg:px-10 rounded-xl relative  p-1 h-full">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-3xl font-semibold text-[#7030a0]">Team Chat</h2>
        <div className="flex items-center space-x-4">
        <button
            onClick={() => setNewChat(true)}
            className="text-white bg-blue-500 px-5 py-2 rounded-full font-semibold flex items-center space-x-2 hover:bg-blue-700"
            title="Add New User"
          >
            <FaPlus className='mr-2' />
            Add User
          </button>
          <button
          onClick={() => handleRefreshClick()}
            className="text-[#ff5d5d] font-semibold flex items-center space-x-2"
            title="Refresh Data"
          >
            <FaSyncAlt
              className={`mr-2 transition-transform ${isRefreshing ? 'animate-spin' : ''}`}
            />
            Refresh
          </button>
        </div>
      </div>
    <div className='w-full'>
        <input
            type="text"
            placeholder="Cerca Utente..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 mb-5 border border-gray-300 rounded"
          />
    </div>
      <div className="flex h-auto border rounded-lg">
        {/* Sidebar for users list */}
        <div className="w-1/3 bg-gray-100 dark:bg-gray-800 p-4 overflow-y-auto">

          <ul className="chat-list">
            {chatHistory.map((chat) => {
                  const unseenMessagesCount = chat.messages
                  .filter(message => message.seen === 0 && message.creator_id !== profile.id && message.message !== "") 
                  .length;

            return (
              <li
                key={chat.user2}
                onClick={() => openChat(chat.user2)}
              >
                {filteredUsers
                  .filter((user) => user.id === chat.user2)
                  .map((user) => (
                    <div className={`rounded-lg mb-2 cursor-pointer flex items-center justify-start py-2 px-1 ${
                        activeChat === chat.user2 ? 'bg-blue-500 text-white' : 'bg-blue-300'
                      }`}>
                    <React.Fragment key={user.id}>                 
                      <img
                        className="h-10 w-10 rounded-full border border-white"
                        src={`https://avatar.iran.liara.run/username?username=${user.name} ${user.surname}`}
                        alt="User Profile"
                      />
                      <span className="ml-3 font-medium">{`${user.name} ${user.surname}`}</span>
                      {unseenMessagesCount > 0 && (
                        <span className="ms-3 bg-red-500 h-[1.5rem] w-[1.5rem] rounded-full flex items-center justify-center text-xs text-white mr-2">
                          {unseenMessagesCount}
                        </span>
                      )}     
                    </React.Fragment>
                    </div>
                  ))}
              </li>
            )})}
          </ul>
        </div>
        <div className="w-2/3 bg-[#e4e4e4] p-4 relative overflow-y-auto m-1 h-[30rem]">
            {activeChat ? (
                <div className="flex flex-col">
                    <div className="message-list flex-1 ">
                    {chatHistory
                        .find((chat) => chat.user2 === activeChat)
                        ?.messages.map((message, index, array) => (
                        <div
                            key={message.id}
                            className={`message mb-3 p-3 relative max-w-max w-auto ${
                            message.creator_id === profile.id ? 'ml-auto text-right' : 'mr-auto text-left'
                            }`}
                            ref={index === array.length - 1 ? lastMessageRef : null} 
                        >
                            {/* Speech bubble container */}
                            <div
                            className={`inline-block rounded-lg px-4 pt-2 pb-5 ${
                                message.creator_id === profile.id ? 'bg-[#7030a0] text-white' : 'bg-blue-500 text-white'
                            } ${message.message === '' && 'hidden'} relative`}
                            >
                            <p className="font-semibold">{message.creator_id === profile.id ? 'Tu' : message.creator_name} :</p>
                            <p>{message.message}</p>
                            <span className="text-sm text-gray-500 block mt-1">
                                {new Date(message.date).toLocaleString()}
                            </span>

                              {/* Triangle corner for the user's own message */}
                                {message.creator_id === profile.id ? (
                                    <>
                                        <div className="absolute bottom-1 left-1">
                                            <FaCheckDouble className={`text-sm ${message.seen === 0 ? 'text-white' : 'text-green-500'}`}/>
                                        </div>
                                        <div className="absolute bottom-0 right-0">
                                            <div
                                                className={`w-0 h-0 border-b-[10px] border-b-[#7030a0] border-l-[10px] border-l-transparent`}
                                            ></div>
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        <div className="absolute bottom-1 right-1">
                                            <FaCheckDouble className={`text-sm ${message.seen === 0 ? 'text-white' : 'text-green-500'}`}/>
                                        </div>
                                        <div className="absolute bottom-0 left-0">
                                            <div
                                                className={`w-0 h-0 border-b-[10px] border-blue-500 border-r-[10px] border-r-transparent`}
                                            ></div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center h-full">
                <p>Seleziona una chat per iniziare a chattare!</p>
                </div>
            )}
            </div>
      </div>
      {activeChat &&
        <div className="mt-4 flex ">
            <textarea
              className="w-full p-2 border rounded-lg"
              placeholder="Scrivi un messaggio..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)} // This ensures state updates on change
              rows="2"
            />
            <button
            onClick={() => sendMessage(activeChat)}
            className="bg-green-500 text-white py-3 px-10 rounded-lg ml-2 h-12"
            >
            {!loading ? 'Invia' : 'Sto Inviando . . .'}
            </button>
        </div>
      }
      <TaskResponsePopup
        show={newChat}
        onClose={() => setNewChat(false)}
        users={users}
        onUserSelect={(id) => handleUserSelect(id)}
      />
    </div>
  );
};

export default ChatComponent;
