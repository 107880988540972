import React from "react";
import { IoClose } from "react-icons/io5";
import MySketch from "./MySketch";

const CatchAvatarGame = ({ onClose }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center  h-full glassmorphism z-50">
      <div className="bg-white text-black rounded-lg shadow-lg  relative overflow-hidden">
        <div className="flex justify-between items-center p-4 bg-black-500 rounded-t-lg">
          <h2 className="text-xl font-semibold text-white">Space Invaders</h2>
          <button onClick={onClose} className="text-red-500">
            <IoClose className="text-xl" />
          </button>
        </div>
        <div className="w-full h-full bg-black-700">
          <MySketch />
        </div>
      </div>
    </div>
  );
};

export default CatchAvatarGame;
