import React from 'react';
import ChatComponent from '../../../components/ChatComponent'


const Chat = () => {

  return (
    <div className="grid h-full grid-cols-1 text-white mt-10">
      <div className="col-span-1 rounded-[20px] bg-white shadow-md dark:bg-grey-800 dark:text-white p-5 md:p-10">
          <ChatComponent />
        </div>
    </div>
  );
};

export default Chat;
