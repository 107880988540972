const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-start px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <p className="mb-4 text-center text-sm text-white sm:!mb-0 md:text-base">
          ©{1900 + new Date().getYear()} Campaign Manager All Rights Reserved.
        </p>
      </h5>
      <div className="flex flex-col items-center justify-center space-y-2 sm:space-y-0 sm:flex-row sm:space-x-4 ms-5">
        <a href="https://www.freeprivacypolicy.com/live/9113e1da-2bfa-4d4d-a8c8-932e77e58871" className="text-sm text-white md:text-base">
          Privacy Policy
        </a>
        <a href="https://www.freeprivacypolicy.com/live/5665170f-942c-4fe7-8611-44f5a8e7e491" className="text-sm text-white md:text-base">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Footer;
