import React from 'react';
import AddClientTable from 'components/AddClientTable';

const AddUser = () => {


  return (
    <div className="grid h-full grid-cols-1 text-white mt-10">
      <div className="col-span-1 rounded-[20px] bg-white shadow-md dark:bg-grey-800 dark:text-white p-5 md:p-10">
          <AddClientTable />
        </div>
    </div>
  );
};

export default AddUser;
