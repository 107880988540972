import React from "react";

// Admin Imports
import Home from "views/admin/home";
// Auth Imports
import SignIn from "views/auth/SignIn";
import Recovery from "views/auth/Recovery";

import { FaRegCalendarCheck } from "react-icons/fa";
import { FaRegCalendarTimes } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import { BsCashCoin, BsChatDots, BsBuildingAdd } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";



// Icon Imports
import {
  MdEventNote,
  MdOutlineLogout,
  MdSecurity, 
} from "react-icons/md";
import ActiveTable from "views/admin/activeDataTable";
import ClosedTable from "views/admin/closedDataTable";
import OnGoingTable from "views/admin/onGoingTable";
import CashTable from "views/admin/cashDataTable";
import NotesTable from "views/admin/ToDoList";
import AddUser from "views/admin/addUser";
import AddClient from "views/admin/addClient";
import ChatComponent from "views/admin/chat/index";



const routes = [
  {
    name: "All Campaigns",
    layout: "/admin",
    path: "dashboard",
    icon: <FaRegCalendarAlt  className="h-6 w-6" />,
    component: <Home />,
  },
  {
    name: "Active",
    layout: "/admin",
    path: "active",
    icon: <FaRegCalendarCheck className="h-6 w-6" />,
    component: <ActiveTable />,
  },
  {
    name: "On Going",
    layout: "/admin",
    path: "on-going",
    icon: <IoRocketOutline  className="h-6 w-6" />,
    component: <OnGoingTable />,
  },
  {
    name: "Closed",
    layout: "/admin",
    path: "closed",
    icon: <FaRegCalendarTimes  className="h-6 w-6" />,
    component: <ClosedTable />,
  },
  {
    name: "Budget",
    layout: "/admin",
    path: "budget",
    icon: <BsCashCoin  className="h-6 w-6" />,
    component: <CashTable />,
  },
  {
    name: "To Do",
    layout: "/admin",
    path: "to-do",
    icon: <MdEventNote  className="h-6 w-6" />,
    component: <NotesTable />,
  },
  {
    name: "Chat",
    layout: "/admin",
    path: "chat",
    icon: <BsChatDots  className="h-6 w-6" />,
    component: <ChatComponent />,
  },
  {
    name: "Esci",
    layout: "/auth",
    path: "sign-in",
    icon: <MdOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Clients",
    layout: "/admin",
    path: "add-client",
    icon: <BsBuildingAdd className="h-6 w-6" />,
    component: <AddClient />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "add-user",
    icon: <FiUserPlus className="h-6 w-6" />,
    component: <AddUser />,
  },
  {
    name: "Recovery password",
    layout: "/recovery",
    path: "pwd",
    icon: <MdSecurity className="h-6 w-6" />,
    component: <Recovery />,
  }
];
export default routes;
