import React from 'react';

export default class GameWon extends React.Component {
	constructor(args) {
		super(args);
		this.state = { score: args.score };
	}

	render() {
		return (
			<div className="game-won-screen">
				<span className="vt323-regular text font-semibold">Sei il miglior Marketing Manager di sempre!</span>
				<span className="vt323-regular text font-semibold">Punteggio: { this.state.score }</span>
				<span className="vt323-regular text font-semibold">Premi ENTER per giocare di nuovo e conquistare il mondo!</span>
			</div>
		);
	}
}
