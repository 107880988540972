import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Recovery() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetToken, setResetToken] = useState(''); // State to hold the token
  const [errorMessage, setErrorMessage] = useState('');
  const [pwdChanged, setPwdChanged] = useState(false);
  const navigate = useNavigate();

  // Extract token from the URL when the component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token'); // Get the token from ?token=...
    if (token) {
      setResetToken(token); // Store the token in state
    }
  }, []);

    // Success modal function
    const SuccessModal = () => (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <h2 className="text-2xl font-semibold text-green-600 mb-4">Password reimpostata con successo!</h2>
          <p className="text-gray-600 mb-4">Ora puoi effettuare l'accesso con la tua nuova password.</p>
          <button
            onClick={navigate('/auth')}
            className="px-6 py-2 text-white bg-green-600 rounded-md hover:bg-green-700"
          >
            Vai alla Pagina di Accesso
          </button>
        </div>
      </div>
    );
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password === confirmPassword) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/pwd/reset-password`, 
          { 
            resetToken: resetToken,
            newPassword: password 
          }
        );
        if (response.status === 200) {
          setPwdChanged(true); 
          return;
        } 
      } catch (error) {
        console.log('Error during API request:', error);
        if (error.response.status === 400) {
          setErrorMessage("La password deve essere di almeno 6 caratteri.");
          return;
        }
        if (error.response.status === 401) {
          setErrorMessage("Sessione scaduta, torna alla login page e riprova.");
          return;
        }
        if (error.response.status === 500) {
          setErrorMessage("Ops! c'è stato un errore, riprova più tardi.");
          return;
        }
      }   
    }else{
      alert("Le password non corrispondono!");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-[#d5abff]">
      {!pwdChanged ? 
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center text-gray-800">Reimposta la tua Password</h2>
        <p className="text-center text-gray-600">Inserisci la tua nuova password qui sotto.</p>

        <form id="resetPasswordForm" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Nuova Password</label>
            <input
              type="password"
              id="password"
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500"
              placeholder="Inserisci la tua nuova password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mt-5">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Conferma Password</label>
            <input
              type="password"
              id="confirmPassword"
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500"
              placeholder="Conferma la tua nuova password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="w-full px-4 py-2 mt-10 font-semibold text-white bg-[#76a540] rounded-md hover:bg-blue-500 focus:outline-none">
            Reimposta Password
          </button>
        </form>
            {errorMessage && (
              <div className="mt-2 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
        <p className="text-sm text-center text-gray-500">
          Hai ricordato la tua password? 
          <a href="https://alessiobutturini.it/auth/sign-in" className="font-semibold text-blue-600 hover:text-blue-500">Accedi</a>
        </p>
      </div>
      :
      <SuccessModal />
      }
    </div>
  );
}

export default Recovery;
