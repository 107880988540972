// TaskResponsePopup.jsx
import React, { useState } from 'react';

const TaskResponsePopup = ({ show, onClose, users, onUserSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');

  if (!show) return null;

  // Filter users based on the search query
  const filteredUsers = users.filter(user =>
    `${user.name} ${user.surname}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center glassmorphism">
      <div className="bg-white p-5 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-lg font-semibold mb-2">Seleziona un Utente!</h2>
        <input
          type="text"
          placeholder="Cerca..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full border rounded p-2 mb-4"
        />
        <div className="max-h-60 overflow-y-auto mb-4">
          <ul className="user-list">
            {filteredUsers.map(user => (
              <li
                key={user.id}
                onClick={() => {
                  onUserSelect(user.id); // Call the onUserSelect prop with user ID
                  onClose(); // Close the popup
                }}
                className="cursor-pointer p-2 hover:bg-gray-200 rounded flex flex-row items-center justify-start"
              >
                <img
                  className="h-10 w-10 rounded-full border border-white me-2"
                  src={`https://avatar.iran.liara.run/username?username=${user.name} ${user.surname}`}
                  alt="User Profile"
                />
                {`${user.name} ${user.surname}`}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="bg-red-500 px-4 py-2 rounded text-white">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskResponsePopup;
