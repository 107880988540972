/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import axios from "axios";
import { GlobalStateContext } from '../../../context/GlobalStateContext';

// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;
  const navigate = useNavigate(); // Create navigate function
  const { notification, setNotification } = useContext(GlobalStateContext); 
  const sessionToken = sessionStorage.getItem('sessionId');

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };


  const fetchSeenChat = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/unread/count`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
      setNotification(response.data[0].unread_count);
    } catch (error) {
      // console.log('Error during API request:', error);
      // alert('Ops, qualcosa è andato storto!');     
    }
  };

  useEffect(() => {
    fetchSeenChat();
  }, [])

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth"
        ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path} className={`${route.name === 'Esci' && 'hidden'}`}>
            <div className="relative mb-3 flex hover:cursor-pointer py-2">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-[#7030a0]"
                      : "font-medium text-gray-100"
                  }`}
                > 
                  {route.name === 'Chat' && notification !== undefined && notification !== null && notification.length !== 0 && notification !== 0 && (
                    <span className="absolute left-[1.3rem] -mt-2 bg-red-500 h-[1.3rem] w-[1.3rem] rounded-full flex items-center justify-center text-xs text-white">
                      {notification}
                    </span>                  
                  )}
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-[#7030a0]"
                      : "font-medium text-gray-100"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-full w-1 rounded-lg bg-[#7030a0]" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  return createLinks(routes);
}

export default SidebarLinks;
