/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Links from "./components/Links";
import Logo from "../../assets/logo/unnamed.png";
import routes from "routes.js";
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';

const Sidebar = ({ open, onClose }) => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate(); // Create navigate function

  const handleSignOut = async () => {
    sessionStorage.removeItem('sessionId');
    navigate('/auth/sign-in');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (window.innerWidth < 1200) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={sidebarRef}
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-grey-700 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >

    <div className="flex items-center justify-center mb-5">
      <div className="flex justify-center items-center w-56 me-0 sme-2 py-">
        <img src={Logo} alt='' className='bg-contain'/>
      </div>
    </div>

      {/* Nav item */}

      <ul className="mb-auto">
        <Links routes={routes} />
        
            <button
              onClick={handleSignOut}
              className="text-red-600 font-semibold flex items-center py-2 px-8 hover:text-red-700"
              title="Sign Out"
            > 
              <BiLogOut  className="h-6 w-6 -ms-1 me-4" />
              Sign Out
            </button>

      </ul>
      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
