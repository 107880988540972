import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/fields/InputField';
import Checkbox from 'components/checkbox';
import Logo from 'assets/logo/9b83ff (5).png';
import Cookies from 'js-cookie';
import sjcl from 'sjcl';
import axios from 'axios';
import { GlobalStateContext } from '../../context/GlobalStateContext';
import RecoveryModal from './components/RecoveryModal';


export default function SignIn() {
  const { setProfile } = useContext(GlobalStateContext); 
  const [utente, setUtente] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showRecovery, setShowRecovery] = useState(false); 
  const navigate = useNavigate();
  const secretKey = 'werk-sfgv-veqg-ergf';
  const savedName = Cookies.get('name');
  const savedPassword = Cookies.get('password');
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (savedName && savedPassword) {
      try {
        function decryptData(encryptedData, key) {
          const decryptedData = sjcl.decrypt(key, encryptedData);
          return decryptedData;
        }

        const decryptedName = decryptData(savedName, secretKey);
        const decryptedPassword = decryptData(savedPassword, secretKey);

        setUtente(decryptedName);
        setPassword(decryptedPassword);
        setIsChecked(true);
      } catch (error) {
        console.error('Decryption error:', error);
      }
    }
  }, [savedName, savedPassword, secretKey]);

  const handleUtenteChange = (e) => {
    const value = e.target.value;
    setUtente(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleCheckboxChange = (checked) => {
    setIsChecked(checked);
  };

  const handleSubmit = async () => {
    setloading(true);
    if (isChecked && !savedName && !savedPassword) {
      const encryptedName = sjcl.encrypt(secretKey, utente);
      const encryptedPassword = sjcl.encrypt(secretKey, password);
  
      Cookies.set('name', encryptedName, { expires: 360 });
      Cookies.set('password', encryptedPassword, { expires: 360 });
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
          email: utente,
          password: password,
        }, {
          withCredentials: true 
        });
  
      if (response.status === 200) {
        sessionStorage.setItem('sessionId', response.data.token);
        setloading(false);
        navigate('/admin');
      } else {
        setErrorMessage('Incorrect username or password');
        setloading(false);
      }
    } catch (error) {
      console.log('Error during API request:', error);
      setloading(false);
      if(error.response.status === 401){
        setErrorMessage('Username o password incorretta');
        return
      }
      setErrorMessage('Ops! qualcosa è andato storto, riprova più tardi!');
    }
  };
  
  const showRecoveryModal = () => {
    setShowRecovery(true); // Show the recovery modal
  };

  const closeRecoveryModal = () => {
    setShowRecovery(false); // Close the recovery modal
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && utente !== '' && password !== '') {
        handleSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown); 
    };
  }, []);
  

  return (
    <div className="mt-[20vh] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="flex justify-center items-center mb-8">
          <img src={Logo} alt="Logo" className="h-20 w-auto" /> 
        </div>
       
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          id="nomeUtente"
          type="text"
          value={utente}
          onChange={handleUtenteChange}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password"
          recoveryPassword={true}
          openRecoveryPassword={showRecoveryModal} // Link to show modal
          id="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />

        <div className="mb-4 mt-5 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox color="blue" checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} />
            <p className="ml-2 text-sm font-medium text-white">Ricordami</p>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="linear mt-10 w-full rounded-xl bg-[#9eea47d9] py-[12px] text-white tracking-wider text-md font-medium transition-transform duration-300 ease-in-out transform hover:scale-[1.0]"
        >
          {loading ? 'Caricamento . . .' : 'Accedi'}
        </button>


        {errorMessage && (
          <div className="mt-4 p-2 text-red-500 bg-white border border-2 border-red-500 rounded">
            {errorMessage}
          </div>
        )}
      </div>

      {showRecovery && <RecoveryModal onClose={closeRecoveryModal} />} {/* Show the modal */}
    </div>
  );
}
