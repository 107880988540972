import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    value,
    onChange,
    recoveryPassword,
    openRecoveryPassword,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${extra} relative`}>
      <label
        htmlFor={id}
        className={`text-sm text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      {recoveryPassword && (
        <button
          onClick={openRecoveryPassword}
          className="ms-3 text-blue-700 text-sm text-shadow tracking-wide"
        >
          Password dimenticata?
        </button>
      )}
        <input
          disabled={disabled}
          type={showPassword ? "text" : type}
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-2 border-[#8dd23e] bg-white p-3 text-sm outline-none text-black ${
            disabled
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
        ></input>
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-3 mt-8 flex items-center text-[#9c83ff]"
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        )}
    </div>
  );
}

export default InputField;
