import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';

const AddUserModal = ({ isOpen, onClose, updateTable }) => {
  const [newUser, setNewUser] = useState({ name: '', surname: '', email: '', password: '' });
  const [loading, setLoading] = useState(false);
  let sessionToken = sessionStorage.getItem('sessionId');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confettiCanvas, setConfettiCanvas] = useState(null);
  const navigate = useNavigate();


  const handleCloseModal = () => {
    onClose();
    setShowSuccessModal(false);
    updateTable()
  }

  const cleanAllData = () => {
    setNewUser({ name: '', surname: '', email: '', password: '' });
  }

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.className = 'confetti-canvas';
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '9999';
    document.body.appendChild(canvas);
    setConfettiCanvas(canvas);
    return () => {
      document.body.removeChild(canvas);
    };
  }, []);


  const launchConfetti = () => {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (confettiCanvas) {
      const confettiInstance = confetti.create(confettiCanvas, {
        resize: true,
        useWorker: true,
      });

      // Launch confetti in three different directions
      confettiInstance({
        angle: randomInRange(55, 70),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(85, 95),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(110, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }
  };

  // Funzione per generare una password casuale
  const generatePassword = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  // Funzione per copiare la password
  const copyPassword = () => {
    setLoading(true);
    navigator.clipboard.writeText(newUser.password);
    setTimeout(() => {
      setLoading(false);
    },1000)
  };

  // Genera una nuova password alla prima apertura del modal
  React.useEffect(() => {
    if (isOpen) {
      const generatedPassword = generatePassword();
      setNewUser({ ...newUser, password: generatedPassword });
    }
  }, [isOpen]);

  // Funzione per gestire l'invio del form
  const handleSubmit = async () => {
    const updatedData = {
      name: newUser.name,
      surname: newUser.surname,
      email: newUser.email,
      password: newUser.password
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/create-user`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionToken,
        },
      });
  
      if (response.status === 201) {
        setShowSuccessModal(true);
        launchConfetti();
        cleanAllData();
        return;
      } 

    } catch (error) {
      if (error.response.status === 409) {
        alert('Questo indirizzo email esiste già, puoi recuperare la password seguendo la procedura guidata al login.')
        return;
      }
      if (error.response.status === 400) {
        alert('Devi compilare tutti i campi prima di procedere!')
        return;
      }
      if (error.response.status === 400) {
        alert('Devi compilare tutti i campi prima di procedere!')
        return;
      }
      if (error.response.status === 419) {
        navigate('/auth/sign-in');
        return;
      }

      console.log('Error during API request:', error);
        alert('Ops, qualcosa è andato storto!')
        return;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center glassmorphism">
      <div className="bg-white p-6 rounded-lg w-96 shadow-xl">
        <h2 className="text-3xl text-start mb-5 font-semibold text-[#7030a0]">Aggiungi Utente</h2>
        <input
          type="text"
          placeholder="Nome"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          className="w-full mb-3 p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="text"
          placeholder="Cognome"
          value={newUser.surname}
          onChange={(e) => setNewUser({ ...newUser, surname: e.target.value })}
          className="w-full mb-3 p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          className="w-full mb-3 p-2 border border-gray-300 rounded"
          required
        />
        <div className="relative mb-8 border border-gray-300 bg-grey-200 rounded text-white">
          <input
            type="text"
            value={newUser.password}
            readOnly
            className="w-full p-2 bg-transparent cursor-not-allowed"
          />
          <button
            onClick={copyPassword}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white"
            title="Copy Password"
          >
            {!loading ? <FaCopy /> : <FaCheck/> } 
          </button>
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-gray-300 bg-red-500 text-white rounded-md"
          >
            Indietro
          </button>
          <button
            onClick={handleSubmit}
            className="px-6 py-2 bg-blue-500 text-white rounded-md"
          >
            Aggiungi
          </button>
        </div>
      </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Congratulazioni!</h3>
            <p>Hai Creato un nuovo utente!</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-green-500 text-white py-2 px-10 rounded"
            >
              Prosegui
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUserModal;
